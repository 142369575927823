import React from "react";
import { motion } from "framer-motion";
import { FaEnvelope, FaGithub } from "react-icons/fa"; // Import icons

const poemLines = [
  "distill the chaos",
  "untangle the threads",
  "yearn for the flow",
  "",
  "harness the hope",
  "unravel the mystery",
  "architect the dream",
];

const App = () => {
  return (
    <div style={styles.container}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5, ease: "easeOut" }}
        style={styles.poem}
      >
        {poemLines.map((line, index) => (
          <motion.div
            key={index}
            style={styles.line}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1.2,
              delay: index * 0.5, // Staggered effect for each line
              ease: "easeOut",
            }}
          >
            {line ? (
              <span>
                <span style={styles.bold}>{line.charAt(0)}</span>
                {line.slice(1)}
              </span>
            ) : (
              <br />
            )}
          </motion.div>
        ))}
        <div style={styles.author}>- transformer</div>
      </motion.div>

      {/* Icons for Email and GitHub */}
      <div style={styles.iconContainer}>
        <motion.a
          href="mailto:mail@duyhua.com"
          style={styles.iconLink}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.9 }}
          title="Email"
        >
          <FaEnvelope style={styles.icon} />
        </motion.a>
        <motion.a
          href="https://github.com/dh1011"
          style={styles.iconLink}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.9 }}
          target="_blank"
          rel="noopener noreferrer"
          title="GitHub"
        >
          <FaGithub style={styles.icon} />
        </motion.a>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#f8f8f2", // Off-white background
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    padding: "20px",
  },
  poem: {
    textAlign: "left", // Align text naturally
    fontFamily: "'Crimson Text', serif",
    fontSize: "1.8rem",
    lineHeight: "2rem",
    color: "#333",
    marginBottom: "20px", // Space for details below
  },
  line: {
    margin: "5px 0", // Closer spacing between each line
  },
  bold: {
    fontWeight: "bold",
  },
  author: {
    marginTop: "10px",
    fontSize: "1rem", // Smaller size for the author
    fontStyle: "italic",
    color: "#555", // Subtle color
    textAlign: "right",
  },
  iconContainer: {
    display: "flex",
    gap: "15px", // Space between icons
    marginTop: "20px",
  },
  iconLink: {
    textDecoration: "none", // Remove underline
  },
  icon: {
    fontSize: "1.8rem", // Icon size
    color: "#333",
    cursor: "pointer",
  },
};

export default App;
